import React, { Component, Fragment } from "react";
import dayjs from "dayjs";
import styled from "styled-components";

// 3rd party components
import Icon from "../Shared/Icon";
import Link from "next/link";

// Components
import Flag from "../Shared/Flag";
import { colors } from "../../config/styles";

// Utils
import { isLive, matchTimeDiff } from "../../util/match-status";
import RouteGenerator from "../../util/route-generator";
import TeamLogo from "../Shared/TeamLogo";
import { S3_BASE_URL } from "../../config/constants";

export default class MatchCardSmall extends Component {
  render() {
    const { match, teamAFavorite, teamBFavorite, tournamentFavorite } =
      this.props;

    return (
      <>
        {!match.isVzone && (
          <Link
            prefetch={false}
            passHref
            href="/partida/[id]"
            as={RouteGenerator.getMatchRoute(match)}
          >
            <MatchCardSmallContainer
              isOver={match.isOver}
              isTBA={match.isTBA}
              isLive={isLive(match.matchDate)}
            >
              <MatchDetailContainer>
                <MatchDetailTeams>
                  <MatchContent>
                    <StyledTeamLogo teamId={match.teamA.teamId} size={12} />
                    {teamAFavorite && (
                      <MatchDetailFavoriteIcon size={12} name="star" />
                    )}
                    <MatchDetailTeamName>
                      {match.teamA.teamName}
                    </MatchDetailTeamName>
                    <MatchDetailScore>
                      {match.bestOf === 1 && (
                        <span>
                          {match.scores[0].scoreA}-{match.scores[0].scoreB}
                        </span>
                      )}
                      {match.bestOf > 1 && (
                        <span>
                          {match.seriesScoreA}-{match.seriesScoreB}
                        </span>
                      )}
                    </MatchDetailScore>
                    <MatchDetailTeamName>
                      {match.teamB.teamName}
                    </MatchDetailTeamName>
                    {teamBFavorite && (
                      <MatchDetailFavoriteIcon size={12} name="star" />
                    )}
                    <StyledTeamLogo teamId={match.teamB.teamId} size={12} />
                  </MatchContent>
                </MatchDetailTeams>

                <MatchDetailTournament>
                  {!!match.isPlayByPlay && !match.isOver && (
                    <MatchDetailPlayByPlayFlag>
                      Tempo real
                    </MatchDetailPlayByPlayFlag>
                  )}
                  {!!match.isPlayByPlay && !!match.isOver && (
                    <MatchDetailPlayByPlayFlagFinished>
                      Reveja os lances
                    </MatchDetailPlayByPlayFlagFinished>
                  )}
                  {match.tournament.tournamentName}
                  {tournamentFavorite && (
                    <MatchDetailFavoriteIcon size={12} name="star" />
                  )}
                </MatchDetailTournament>

                <MatchTimeStatus>
                  {!match.isTBA && (
                    <Fragment>
                      {dayjs(match.matchDate, "X").format("HH:mm")} -&nbsp;
                    </Fragment>
                  )}

                  {!!match.isTBA && "TBA"}
                  <MatchTimer>
                    {!match.isOver && !isLive(match.matchDate) && !match.isTBA && (
                      <Fragment>
                        <MatchTimerIcon size={11} name="clock" />
                        {matchTimeDiff(match.matchDate)}
                      </Fragment>
                    )}

                    {!match.isOver &&
                      isLive(match.matchDate) &&
                      !match.isTBA && <MatchLive>Ao vivo</MatchLive>}

                    {!!match.isOver && (
                      <MatchFinished>Finalizado</MatchFinished>
                    )}
                  </MatchTimer>
                </MatchTimeStatus>
              </MatchDetailContainer>
              <MatchStream>
                {!!match.isFinished && (
                  <>
                    <MatchLinkIconFinished size={24} name="television" />
                  </>
                )}
                {!!match.isStreamed && !match.isFinished && (
                  <>
                    <MatchLinkIcon size={24} name="television" />
                  </>
                )}

                {!match.isStreamed && !match.isFinished && (
                  <MatchLinkIcon size={24} name="televisionoff" />
                )}
              </MatchStream>
            </MatchCardSmallContainer>
          </Link>
        )}
        {!!match.isVzone && (
          <a
            prefetch={false}
            passHref
            // href="/partida/[id]"
            target="_blank"
            href={RouteGenerator.getMatchRoute(match)}
            as={RouteGenerator.getMatchRoute(match)}
          >
            <MatchCardSmallContainerVzone
              isOver={match.isOver}
              isTBA={match.isTBA}
              isLive={isLive(match.matchDate)}
            >
              <MatchDetailContainer>
                <MatchDetailTeams>
                  <MatchContent>
                    <StyledTeamLogo teamId={match.teamA.teamId} size={12} />
                    {teamAFavorite && (
                      <MatchDetailFavoriteIcon size={12} name="star" />
                    )}
                    <MatchDetailTeamName>
                      {match.teamA.teamName}
                    </MatchDetailTeamName>
                    <MatchDetailScore>{"vs"}</MatchDetailScore>
                    <MatchDetailTeamName>
                      {match.teamB.teamName}
                    </MatchDetailTeamName>
                    {teamBFavorite && (
                      <MatchDetailFavoriteIcon size={12} name="star" />
                    )}
                    <StyledTeamLogo teamId={match.teamB.teamId} size={12} />
                  </MatchContent>
                </MatchDetailTeams>

                <MatchDetailTournament>
                  {match.tournamentVzone}
                  <RedirectIconTournamentVZone size={8} name="redirect" />
                </MatchDetailTournament>

                <MatchTimeStatus>
                  <MatchTimerVzone>
                    {/* TBA */}
                    {match.isTBA === 1 && "TBA"}

                    {/* Finished */}
                    {!!match.isOver && "Finalizado"}

                    {/* Live */}
                    {!match.isOver &&
                      isLive(match.matchDate) &&
                      !match.isTBA && <MatchLive>Ao vivo</MatchLive>}

                    {/* {match.matchDate} */}

                    {/* Before Live */}
                    {!isLive(match.matchDate) &&
                      !match.isTBA &&
                      !match.isOver &&
                      dayjs(match.matchDate, "X").format("HH:mm")}

                    <CtaVzone>- Central do VAL no BR</CtaVzone>
                  </MatchTimerVzone>
                </MatchTimeStatus>
              </MatchDetailContainer>
              <MatchLinkIconVzone size={32} name="vzone" />
            </MatchCardSmallContainerVzone>
          </a>
        )}
      </>
    );
  }
}

const MatchCardSmallContainer = styled.a`
  align-items: center;
  background: ${(props) =>
    props.isOver === 1 ? colors.FINISHED_LIGHT : "white"};
  box-shadow: 0 0px 35px rgba(50, 50, 93, 0.01), 0 5px 15px rgba(0, 0, 0, 0.07);
  color: ${colors.MAIN};
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  padding: 12px 10px;
  position: relative;
  transition: all 0.2s;
  box-sizing: border-box;
  border-left: ${(props) => {
    if (props.isOver === 0 && props.isLive && props.isTBA === 0) {
      return `5px solid ${colors.LIVE}`;
    }

    if (props.isOver === 1) {
      return `5px solid ${colors.FINISHED}`;
    }

    return `5px solid ${colors.MAIN_LIGHTER}`;
  }};

  @media screen and (max-width: 768px) {
    padding: 24px;
    margin-bottom: 16px;
  }
`;
const MatchCardSmallContainerVzone = styled.a`
  align-items: center;
  background: ${(props) =>
    props.isOver === 1 ? colors.FINISHED_LIGHT : colors.VZONE_LIGHTEST};
  background-image: url("${S3_BASE_URL}/assets/vzone/matchcard-bg.png");
  background-repeat: no-repeat;
  background-position: 150px 20px;
  background-size: 200px;

  font-style: italic;

  box-shadow: 0 0px 15px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  color: ${colors.MAIN};
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  padding: 12px 10px;
  position: relative;
  transition: all 0.2s;
  box-sizing: border-box;
  border-left: ${(props) => {
    if (props.isOver === 0 && props.isLive && props.isTBA === 0) {
      return `5px solid ${colors.LIVE}`;
    }

    if (props.isOver === 1) {
      return `5px solid ${colors.FINISHED}`;
    }

    return `5px solid ${colors.MAIN_LIGHTER}`;
  }};

  @media screen and (max-width: 768px) {
    padding: 24px;
    margin-bottom: 16px;

    background-position: 500px 20px;
    background-size: 250px;
  }
`;

// Match Detail
// =========================
const MatchDetailContainer = styled.div`
  flex: 1;
  width: calc(100% - 70px);
`;

const MatchDetailTeams = styled.div`
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.009em;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

const MatchContent = styled.span`
  display: inline-flex;
  white-space: nowrap;
  margin: auto 0px;
  width: 100%;
  align-items: center;
`;

const StyledTeamLogo = styled(TeamLogo)`
  margin-right: 1px;
  margin-left: 1px;

  @media screen and (max-width: 768px) {
    position: relative;
    top: 5px;
  }
`;

const MatchDetailTeamName = styled.span`
  display: inline-block;
  white-space: normal;
  max-width: 105px;
  margin: 0px 2px;
  white-space: nowrap;
  overflow: hidden; /* "overflow" value must be different from  visible"*/
  -o-text-overflow: ellipsis; /* Opera < 11*/
  text-overflow: ellipsis; /* IE, Safari (WebKit), Opera >= 11, FF > 6 */

  @media screen and (max-width: 768px) {
    max-width: none;
    white-space: unset;
  }
`;

const MatchDetailScore = styled.span`
  font-weight: normal;
  //min-width: 20px;
  color: ${colors.GRAY_LIGHT};
  margin: 0px 3px;
`;

const MatchDetailTournament = styled.div`
  margin-top: 2px;
  font-size: 12px;
  max-width: 225px;
  white-space: nowrap;
  overflow: hidden; /* "overflow" value must be different from  visible"*/
  -o-text-overflow: ellipsis; /* Opera < 11*/
  text-overflow: ellipsis; /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
  color: ${colors.GRAY_LIGHT};
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 22px;
    margin-top: 4px;
    margin-bottom: 4px;
    max-width: 720px;
  }
`;

const MatchDetailPlayByPlayFlag = styled.span`
  background: ${colors.LIVE};
  font-size: 10px;
  color: white;
  font-weight: bold;
  padding: 2px 3px;
  border-radius: 3px;
  margin-right: 4px;
`;

const MatchDetailPlayByPlayFlagFinished = styled.span`
  background: ${colors.FINISHED};
  font-size: 10px;
  color: white;
  font-weight: bold;
  padding: 2px 3px;
  border-radius: 3px;
  margin-right: 4px;
`;
const MatchDetailFavoriteIcon = styled(Icon)`
  fill: ${colors.FAVORITE};
  margin-left: 4px;
  position: relative;

  &:first-of-type {
    margin-right: 4px;
    margin-left: 0px;
  }
`;

// Match Time
// =========================
const MatchTimeStatus = styled.div`
  margin-top: 2px;
  font-size: 12px;
  color: ${colors.GRAY};

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const MatchTimer = styled.span`
  font-size: 11px;
  margin-right: 8px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;
const MatchTimerVzone = styled.span`
  font-size: 11px;
  margin-right: 8px;
  display: flex;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const MatchFinished = styled.span`
  color: ${colors.FINISHED};
`;
const MatchLive = styled.span`
  color: ${colors.LIVE};
`;

const MatchTimerIcon = styled(Icon)`
  fill: ${colors.GRAY_LIGHT};
  margin-right: 3px;
`;

// Match Stream
// =========================
const MatchStream = styled.div`
  text-align: center;
  display: flex;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const MatchLinkIcon = styled(Icon)`
  fill: ${colors.TWITCH};
`;
const MatchLinkIconVzone = styled(Icon)`
  fill: ${colors.VZONE_ACCENT};
`;

const MatchLinkIconFinished = styled(Icon)`
  fill: ${colors.FINISHED_LIGHT};
`;

const RedirectIconTournamentVZone = styled(Icon)`
  fill: ${colors.VZONE_ACCENT};
  margin-right: 4px;
  margin-left: 4px;

  @media screen and (max-width: 768px) {
    width: 16px;
    height: 16px;
  }
`;

const CtaVzone = styled.div`
  font-size: 10px;
  margin-left: 2px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    margin-left: 3px;
  }
`;
