import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isYesterday from "dayjs/plugin/isYesterday";

export function isLive(matchDate) {
  const now = dayjs();
  const match = dayjs(matchDate * 1000);

  return now > match;
}

export function dateIsToday(matchDate) {
  dayjs.extend(isToday);
  const match = dayjs(matchDate * 1000).isToday();

  return match;
}

export function dateIsTomorrow(matchDate) {
  dayjs.extend(isTomorrow);
  const match = dayjs(matchDate * 1000).isTomorrow();

  return match;
}
export function dateIsYesterday(matchDate) {
  dayjs.extend(isYesterday);
  const match = dayjs(matchDate * 1000).isYesterday();

  return match;
}

export function matchTimeDiff(matchDate) {
  const now = dayjs();
  const match = dayjs(matchDate * 1000);

  return dayjs.duration(match.diff(now)).humanize(true);
}
