import axios from "axios";
import { API_BASE_URL } from "../config/constants";

const serviceUrl = `${API_BASE_URL}/live-odds`;

const TWO_DAYS_MILLIS = 1 * 24 * 60 * 60 * 1000;

export default class LiveOddsService {
  static async getServerOddsByMatchesIds(matchesIds, oddProvider = null) {
    const params = {
      "matchesIds[]": matchesIds,
    };

    if (oddProvider) params.oddProvider = oddProvider;

    return await axios.get(`${serviceUrl}/odds`, { params });
  }

  static async getOddsByMatches(matches, oddProvider = null) {
    if (matches && matches.length) {
      const matchesIds = matches
        .filter(
          (m) =>
            !m.isFinished &&
            !m.isOver &&
            m.matchDate < Date.now() + TWO_DAYS_MILLIS
        )
        .map((m) => m.matchId);
      try {
        const response = await LiveOddsService.getServerOddsByMatchesIds(
          matchesIds,
          oddProvider
        );
        return response.data.data;
      } catch (error) {
        console.error("Error on getServerOddsByMatchesIds:", error.message);
      }
    }
    return [];
  }

  static findOdd(match, odds) {
    return odds && odds.find
      ? odds.find((matchOdd) => matchOdd.matchId == match.matchId)
      : null;
  }
}
