import axios from "axios";
import { TS_API_BASE_URL } from "../config/constants";


export default class MatchService {
  static getMatchDetail(matchId) {
    const url = `${TS_API_BASE_URL}/matches/${matchId}`;

    return axios.get(url);
  }

  static getMatches(
    page = 1,
    amount = 50,
    finished = 0,
    featured = 0,
    team = null,
    tournament = null,
    showHidden = 0,
  ) {
    return this.getMatchesV2({
      page,
      amount,
      finished,
      featured,
      team,
      tournament,
      showHidden
    })
  }

  static getMatchesV2(
    queryParams = {}
  ) {
    const queryWithDefaultParams = {
      page: 1,
      amount: 50,
      finished: 0,
      featured: 0,
      team: null,
      tournament: null,
      showHidden: 0,
      ...queryParams
    }

    const { page,
      amount,
      finished,
      featured,
      team,
      tournament,
      showHidden
    } = queryWithDefaultParams;

    const params = {
      page,
      amount,
      finished,
      featured,
      team,
      tournament,
      showHidden
    };

    const url = `${TS_API_BASE_URL}/matches`;

    return axios.get(url, {
      params,
    });
  }
}
