import Card from "../Shared/Card";
import styled from "styled-components";
import dayjs from "dayjs";
import { colors } from "../../config/styles";
import Link from "next/link";
import RouteGenerator from "../../util/route-generator";
import Icon from "../Shared/Icon";
import TeamLogo from "../Shared/TeamLogo";

const MatchCardTiny = (props) => {
  return (
    <Link
      prefetch={false}
      passHref
      href="/partida/[id]"
      as={RouteGenerator.getOldMatchRoute(props.match)}
    >
      <MatchCardTinyContainer>
        <MatchCard>
          <MatchDate>
            {dayjs(props.match.matchDate, "X").format("DD/MM")}
          </MatchDate>
          <TeamName>
            <TeamLogo teamId={props.match.teamA.id} size={20} />
            {props.match.series.scoreA}
          </TeamName>
          -
          <TeamName>
            {props.match.series.scoreB}
            <TeamLogo teamId={props.match.teamB.id} size={20} />
          </TeamName>
          <TournamentName>{props.match.tournament.name}</TournamentName>
          <Icon size={16} name="caret-right" />
        </MatchCard>
      </MatchCardTinyContainer>
    </Link>
  );
};

const MatchCardTinyContainer = styled.a`
  cursor: pointer;
`;

const MatchCard = styled(Card)`
  padding: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  fill: ${colors.ACCENT};

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const MatchDate = styled.span`
  margin-right: 8px;
`;

const TeamName = styled.span`
  text-align: left;
  margin: 0 4px;
  color: ${colors.ACCENT};
  display: flex;
  align-items: center;

  img {
    margin: 0 4px;
  }
`;

const TournamentName = styled.span`
  color: ${colors.GRAY_LIGHT};
  flex: 1;
  text-align: right;
  margin-right: 8px;
`;

export default MatchCardTiny;
