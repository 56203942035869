import React, { useState } from "react";
import styled from "styled-components";
import LiveOddsModal from "./LiveOddsModal";
import { colors } from "../../config/styles";

const LiveOddsBox = ({ odd, teamA, teamB, teamKey }) => {
  if (!odd) return null;

  const [showModal, setShowModal] = useState(false);

  const openModal = (event) => {
    event.preventDefault();
    setShowModal(true);

    const props = { teamA, teamB };

    window.dataLayer.push({
      event: "providerClick",
      trackingProvider: `OpenOddsModal-TeamId${props[teamKey].teamId}`,
    });
  };
  const oddValue = odd[`${teamKey}Odd`];

  if (!oddValue) return null;

  return (
    <div>
      <LiveOddsModal
        onClose={() => setShowModal(false)}
        show={showModal}
        odd={odd}
        teamA={teamA}
        teamB={teamB}
      />
      <OddBox onClick={openModal}>
        <OddBoxText>{oddValue.toFixed(2)}</OddBoxText>
      </OddBox>
    </div>
  );
};

export default LiveOddsBox;

const OddBoxText = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px !important;

  color: ${colors.ACCENT} !important;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 15px !important;
  }
`;
const OddBox = styled.button`
  cursor: pointer;
  box-sizing: border-box;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 42px;
  height: 20px;

  background: ${colors.ACCENT_LIGHTEST};

  border: 1px solid ${colors.ACCENT};
  border-radius: 6px;
  margin-left: 8px;
`;
