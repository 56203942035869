import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Icon from "../Shared/Icon";
import { colors } from "../../config/styles";

const LiveOddsModal = ({ show, onClose, odd, teamA, teamB }) => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  const openExternalLink = (provider, teamData) => {
    window.dataLayer.push({
      event: "providerClick",
      trackingProvider: `livOddProvider-${provider.liveOddProviderName}`,
    });

    const { providerData } = teamData;
    const link = providerData.link || providerData.url;
    window.open(link, "_blank");
  };

  const renderLine = ({ provider, matchOdd }, index) => {
    return (
      <TableLine key={`${provider.name}${index}`}>
        <TdCenter>
          <img
            src={provider.liveOddProviderImageUrl}
            alt={provider.liveOddProviderName}
          />
        </TdCenter>
        <TdCenter>
          <PromotionalText>
            {provider.liveOddProviderPromotional}
          </PromotionalText>
        </TdCenter>
        <TdCenter>
          <OddBox
            onClick={() => openExternalLink(provider, matchOdd.teamA)}
            bigger={matchOdd.teamA.odd == odd.teamAOdd}
          >
            {matchOdd.teamA.odd.toFixed(2)}
          </OddBox>
        </TdCenter>
        <TdCenter>
          <OddBox
            onClick={() => openExternalLink(provider, matchOdd.teamB)}
            bigger={matchOdd.teamB.odd == odd.teamBOdd}
          >
            {matchOdd.teamB.odd.toFixed(2)}
          </OddBox>
        </TdCenter>
      </TableLine>
    );
  };

  const modalContent = show ? (
    <StyledModalOverlay
      onClick={(event) => {
        // Evita abertura da pagina de partida
        if (event.isDefaultPrevented()) {
          return;
        }
        event.preventDefault();
        handleCloseClick(event);
      }}
    >
      <StyledModal
        onClick={(event) => event.preventDefault()} // Evitar abrir o card de partida
      >
        <StyledModalHeader>
          <a href="#" onClick={handleCloseClick}>
            <Icon name="close-modal" size={16} />
          </a>
        </StyledModalHeader>
        <StyledModalTitle>
          Odds de aposta para {teamA.teamName} vs. {teamB.teamName}
        </StyledModalTitle>
        <StyledModalBody>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <tr>
              <TdCenter>
                <TableHeader>Site</TableHeader>
              </TdCenter>
              <TdCenter>
                <TableHeader>Promoção</TableHeader>
              </TdCenter>
              <TdCenter>
                <TableHeader>{teamA.teamName}</TableHeader>
              </TdCenter>
              <TdCenter>
                <TableHeader>{teamB.teamName}</TableHeader>
              </TdCenter>
            </tr>
            {odd.providers.map((iOddMatch, index) =>
              renderLine(iOddMatch, index)
            )}
          </table>
        </StyledModalBody>
        <StyledModalFooter>
          +18. Aposte com responsabilidade. Leia os termos de uso da(s)
          plataforma(s) antes de apostar.
        </StyledModalFooter>
      </StyledModal>
    </StyledModalOverlay>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById("odd-modal-root")
    );
  } else {
    return null;
  }
};

const StyledModalBody = styled.div`
  padding-top: 10px;
`;

const StyledModalHeader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -10px;
  margin-right: -10px;
`;

const StyledModalTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 12px;
  color: ${colors.GRAY_DARK};

  @media screen and (max-width: 768px) {
    font-size: 24px;
    padding: 0 0 15px 0;
  }
`;

const StyledModalFooter = styled.div`
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
  padding-top: 5px;
  text-align: center;
  color: ${colors.GRAY_LIGHT};

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const StyledModal = styled.div`
  position: relative;
  padding: 15px 15px 30px 15px;
  background: #ffffff;
  box-shadow: 0px 0px 25px ${colors.GRAY_DARKER};
  border-radius: 2px;
  margin-top: -50px;

  @media screen and (max-width: 768px) {
    margin-top: -400px;
    padding: 30px;
  }
`;
const StyledModalOverlay = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

const TableLine = styled.tr`
  border-bottom: 1px solid ${colors.GRAY_LIGHTER};
`;
const TableHeader = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.GRAY_DARK};

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;
const PromotionalText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  color: ${colors.GRAY};

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const OddBox = styled.button`
  cursor: pointer;
  box-sizing: border-box;
  background: ${(props) =>
    props.bigger ? `${colors.ODDS_BETTER_LIGHT}` : `${colors.ACCENT_LIGHTEST}`};
  border: 1px solid
    ${(props) =>
      props.bigger ? `${colors.ODDS_BETTER_MAIN}` : `${colors.ACCENT}`};
  border-radius: 6px;
  margin: 10px 0px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  color: ${(props) =>
    props.bigger ? `${colors.ODDS_BETTER_MAIN}` : `${colors.ACCENT}`};

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const TdCenter = styled.td`
  text-align: center;
  vertical-align: middle;
  padding: 0 10px;
`;

export default LiveOddsModal;
