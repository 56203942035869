import React, { Component, Fragment } from "react";
import dayjs from "dayjs";
import styled from "styled-components";

// 3rd party components
import Icon from "../Shared/Icon";
import Link from "next/link";

// Components
import Flag from "../Shared/Flag";
import { colors } from "../../config/styles";

// Utils
import { isLive, matchTimeDiff } from "../../util/match-status";
import RouteGenerator from "../../util/route-generator";

export default class MatchCard extends Component {
  render() {
    const { match, teamAFavorite, teamBFavorite, tournamentFavorite } =
      this.props;

    return (
      <Link
        prefetch={false}
        passHref
        href="/partida/[id]"
        as={RouteGenerator.getMatchRoute(match)}
      >
        <a>
          <MatchCardContainer
            isOver={match.isOver}
            isTBA={match.isTBA}
            isLive={isLive(match.matchDate)}
          >
            <MatchTime>
              {match.isTBA === 1 && <span>TBA</span>}

              {match.isTBA === 0 && dayjs(match.matchDate, "X").format("HH:mm")}
            </MatchTime>

            <MatchDetail>
              <MatchDetailTeams>
                <MatchDetailFlag country={match.teamA.country} size={15} />
                {teamAFavorite && (
                  <MatchCardFavoriteIcon size={14} name="star" />
                )}
                {match.teamA.teamName}
                <MatchDetailScore>
                  {match.bestOf === 1 && (
                    <span>
                      {match.scores[0].scoreA}-{match.scores[0].scoreB}
                    </span>
                  )}
                  {match.bestOf > 1 && (
                    <span>
                      {match.scores.scoreA}-{match.scores.scoreB}
                    </span>
                  )}
                </MatchDetailScore>
                {match.teamB.teamName}
                {teamBFavorite && (
                  <MatchCardFavoriteIcon size={14} name="star" />
                )}
                <MatchDetailFlag country={match.teamB.country} size={15} />
              </MatchDetailTeams>

              <MatchDetailMaps>
                {match.scores.map((score, i) => (
                  <MatchDetailMap key={`${score.map.mapName}-${i}`}>
                    {score.scoreA}:{score.scoreB} - {score.map.mapName}
                  </MatchDetailMap>
                ))}
              </MatchDetailMaps>

              <MatchDetailTournament>
                {match.isPlayByPlay && (
                  <MatchCardPlayByPlayLabel>
                    Tempo real
                  </MatchCardPlayByPlayLabel>
                )}

                {match.tournament.tournamentName}
                {tournamentFavorite && <MatchCardFavoriteIcon name="star" />}
              </MatchDetailTournament>
            </MatchDetail>

            <MatchStream>
              {match.isStreamed === 1 && match.mainStream && (
                <Fragment>
                  <MatchStreamIcon
                    platform={match.mainStream.streamPlatform}
                    name={(() => {
                      if (match.mainStream.streamPlatform === "Twitch")
                        return "twitch";
                      if (
                        match.mainStream.streamPlatform === "YouTubeGaming" ||
                        match.mainStream.streamPlatform === "YouTubeEmbed"
                      )
                        return "youtube";
                      if (match.mainStream.streamPlatform === "FacebookLive")
                        return "facebook";

                      return "video";
                    })()}
                  />
                  {match.mainStream.streamName}
                </Fragment>
              )}

              {match.isStreamed === 0 && "transmissão não cadastrada"}
            </MatchStream>

            <MatchTimer
              isOver={match.isOver}
              isLive={isLive(match.matchDate)}
              isTBA={match.isTBA}
            >
              {match.isOver === 0 &&
                !isLive(match.matchDate) &&
                match.isTBA === 0 && (
                  <Fragment>
                    <MatchTimerIcon name="clock" />
                    {matchTimeDiff(match.matchDate)}
                  </Fragment>
                )}

              {match.isOver === 0 &&
                isLive(match.matchDate) &&
                match.isTBA === 0 && (
                  <Fragment>
                    <Icon name="circle" /> Ao Vivo
                  </Fragment>
                )}

              {match.isTBA === 1 && ""}

              {match.isOver === 1 && match.isTBA === 0 && "Finalizado"}

              {match.isPlayByPlay && (
                <MatchCardPlayByPlayLabel mobile>
                  Tempo real
                </MatchCardPlayByPlayLabel>
              )}
            </MatchTimer>

            <MatchLinkIcon name="plus" />
          </MatchCardContainer>
        </a>
      </Link>
    );
  }
}

// MATCH Container
// ===========================
const MatchCardContainer = styled.a`
  align-items: center;
  background: ${(props) =>
    props.isOver === 1 ? colors.FINISHED_LIGHT : "white"};
  border-left: ${(props) => {
    if (props.isOver === 0 && props.isLive && props.isTBA === 0) {
      return `5px solid ${colors.LIVE}`;
    }

    if (props.isOver === 1) {
      return `5px solid ${colors.FINISHED}`;
    }

    return `5px solid ${colors.MAIN_LIGHTER}`;
  }};
  box-shadow: 0 0px 35px rgba(50, 50, 93, 0.01), 0 5px 15px rgba(0, 0, 0, 0.07);
  color: ${colors.MAIN};
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  min-height: 100px;
  position: relative;
  padding: 15px 0;
  transition: all 0.2s;
  box-sizing: border-box;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
`;

// MATCH Time
// ===========================
const MatchTime = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  font-weight: bold;
  text-align: center;
  font-size: 14px;

  @media screen and (max-width: 576px) {
    margin: 0px;
    font-size: 12px;
  }
`;

// MATCH Stream
// ===========================
const MatchStream = styled.div`
  text-align: center;
  width: 13%;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const MatchStreamIcon = styled(Icon)`
  font-size: 24px;
  display: block;
  margin-bottom: 8px;
  color: ${({ platform }) => {
    if (platform === "Twitch") return colors.TWITCH;
    if (platform === "YouTubeEmbed" || platform === "YouTubeGaming")
      return colors.YOUTUBE;
    if (platform === "FacebookLive") return colors.FACEBOOK;

    return colors.MAIN_LIGHTER;
  }};

  @media only screen and (max-width: 576px) {
    font-size: 14px;
    margin-bottom: 0px;
    margin-right: 8px;
  }
`;

// MATCH Link
// ===========================
const MatchLinkIcon = styled(Icon)`
  text-align: right;
  color: ${colors.GRAY_LIGHT};
  transition: all 0.4s;
  margin-right: 24px;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

// MATCH detail
// ===========================
const MatchDetail = styled.div`
  flex: 1;
`;

const MatchDetailTeams = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.009em;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 576px) {
    text-align: center;
    margin: 12px 0;
  }
`;

const MatchDetailFlag = styled(Flag)`
  margin: 0 4px;

  &:first-of-type {
    margin-left: 0px;
  }
`;

const MatchDetailScore = styled.span`
  font-weight: normal;
  color: ${colors.GRAY_LIGHT};
  margin: 0px 5px;

  @media only screen and (max-width: 576px) {
    font-size: 14px;
  }
`;

const MatchDetailMaps = styled.div`
  clear: both;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const MatchDetailMap = styled.div`
  font-size: 12px;
  font-weight: normal;
  background: ${colors.GRAY_LIGHTEST};
  color: ${colors.GRAY};
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 2px 5px;
  border-radius: 5px;
`;

const MatchDetailTournament = styled.div`
  margin-top: 2px;
  font-size: 12px;
  color: ${colors.GRAY_LIGHT};

  @media only screen and (max-width: 576px) {
    margin: 8px 0;
    text-align: center;
  }
`;

const MatchCardPlayByPlayLabel = styled.span`
  background: ${colors.LIVE};
  font-size: 11px;
  color: white;
  font-weight: bold;
  padding: 3px 4px;
  border-radius: 5px;
  margin-right: 8px;
  margin-left: ${(props) => (props.mobile ? "8px" : "0px")};

  display: ${(props) => (props.mobile ? "none" : "inline-block")};

  @media screen and (max-width: 576px) {
    display: ${(props) => (props.mobile ? "inline-block" : "none")};
  }
`;

const MatchCardFavoriteIcon = styled(Icon)`
  fill: ${colors.FAVORITE};
  margin-left: 4px;
  position: relative;

  &:first-of-type {
    margin-right: 4px;
    margin-left: 0px;
  }
`;

// Match Timer
// ===========================
const MatchTimer = styled.div`
  flex-basis: 22%;
  text-align: right;
  font-size: 12px;
  color: ${colors.MAIN_LIGHTER};
  font-weight: bold;
  margin-right: 8px;

  color: ${(props) => {
    if (props.isOver === 0 && props.isLive && props.isTBA === 0) {
      return colors.LIVE;
    }
    return colors.MAIN_LIGHTER;
  }};

  @media only screen and (max-width: 576px) {
    margin: 8px 0;
    text-align: center;
  }
`;

const MatchTimerIcon = styled(Icon)`
  color: ${colors.GRAY_LIGHT};
  margin-right: 3px;
`;
