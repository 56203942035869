import React, { Component, Fragment } from "react";
import groupBy from "lodash/groupBy";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isYesterday from "dayjs/plugin/isYesterday";
import styled from "styled-components";

// 3rd
import LazyLoad from "react-lazyload";

// Components
import MatchCard from "./MatchCard";
import EmptyState from "../Shared/EmptyState";
import MatchCardSmall from "./MatchCardSmall";

// Context
import { UserContext } from "../../contexts/UserContext";
import UserContextFormatter from "../../util/user-context-formatter";
import MatchCardTiny from "./MatchCardTiny";
import { colors } from "../../config/styles";

// Components
// import LoadingIndicator from '../../../__components/Shared/LoadingIndicator';

class MatchList extends Component {
  constructor(props, context) {
    super(props);

    const { teams, tournaments } = context.favorites;

    this.state = {
      favoriteTeamsIds: UserContextFormatter.getTeamIdsFromFavorites(teams),
      favoriteTournamentIds:
        UserContextFormatter.getTournamentIdsFromFavorites(tournaments),
      matches: [],
    };
  }

  componentDidMount() {
    this.divideMatchListByDay();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.divideMatchListByDay();
    }
  }

  divideMatchListByDay = () => {
    // Add matchDay key to matchList
    const matchesWithDay = this.props.data.map((match) => {
      const matchDay = dayjs(match.matchDate, "X").hour(0).minute(0);

      return {
        ...match,
        matchDay: matchDay.format("X"),
      };
    });

    // Group by day
    const groupedByDay = groupBy(matchesWithDay, "matchDay");

    this.setState({
      matches: groupedByDay,
    });
  };

  isFavoriteTeam = (teamId) => {
    const { favoriteTeamsIds } = this.state;

    return favoriteTeamsIds.indexOf(teamId) >= 0;
  };

  isFavoriteTournament = (tournamentId) => {
    const { favoriteTournamentIds } = this.state;

    return favoriteTournamentIds.indexOf(tournamentId) >= 0;
  };

  render() {
    const { matches } = this.state;

    const { reverse, noDates, component } = this.props;

    const MatchCardComponent = component;

    const keyArray = reverse
      ? Object.keys(matches).reverse()
      : Object.keys(matches);

    return (
      <Fragment>
        {/* ============================= */}
        {/*  loading state                */}
        {/* ============================= */}
        {/* {
          this.props.isFetching &&
          <Card>
            <LoadingIndicator text="Carregando partidas..." />
          </Card>
        } */}

        {/* ============================= */}
        {/*  empty state                  */}
        {/* ============================= */}
        {!this.props.isFetching && this.props.data.length === 0 && (
          <EmptyState text="Nenhuma partida encontrada." />
        )}

        {/* ============================= */}
        {/*  normal state                 */}
        {/* ============================= */}
        {!this.props.isFetching && (
          <Fragment>
            {keyArray.map((key) => (
              <Fragment key={key}>
                {!noDates && (
                  <MatchListDate>
                    {dayjs.extend(isYesterday)}
                    {dayjs.extend(isToday)}
                    {dayjs.extend(isTomorrow)}

                    {dayjs(key, "X").isYesterday() &&
                      dayjs(key, "X").format("[Ontem], D [de] MMMM [de] YYYY")}

                    {dayjs(key, "X").isToday() &&
                      dayjs(key, "X").format("[Hoje], D [de] MMMM [de] YYYY")}

                    {dayjs(key, "X").isTomorrow() &&
                      dayjs(key, "X").format("[Amanhã], D [de] MMMM [de] YYYY")}

                    {!dayjs(key, "X").isToday() &&
                      !dayjs(key, "X").isTomorrow() &&
                      !dayjs(key, "X").isYesterday() &&
                      dayjs(key, "X").format("dddd, D [de] MMMM [de] YYYY")}
                  </MatchListDate>
                )}

                {matches[key].map((match) => {
                  if (MatchCardComponent) {
                    return (
                      <MatchCardComponent
                        key={match.matchId}
                        match={match}
                        teamAFavorite={this.isFavoriteTeam(match.teamA.teamId)}
                        teamBFavorite={this.isFavoriteTeam(match.teamB.teamId)}
                        tournamentFavorite={this.isFavoriteTournament(
                          match.tournament.tournamentId
                        )}
                        odds={this.props.odds}
                      />
                    );
                  }

                  if (this.props.tiny) {
                    return (
                      <MatchCardTiny
                        key={match.matchId}
                        match={match}
                        teamAFavorite={this.isFavoriteTeam(match.teamA.teamId)}
                        teamBFavorite={this.isFavoriteTeam(match.teamB.teamId)}
                        tournamentFavorite={this.isFavoriteTournament(
                          match.tournament.tournamentId
                        )}
                      />
                    );
                  }

                  if (this.props.small) {
                    return (
                      <LazyLoad height={88} key={match.matchId}>
                        <MatchCardSmall
                          match={match}
                          teamAFavorite={this.isFavoriteTeam(
                            match.teamA.teamId
                          )}
                          teamBFavorite={this.isFavoriteTeam(
                            match.teamB.teamId
                          )}
                          tournamentFavorite={this.isFavoriteTournament(
                            match.tournament.tournamentId
                          )}
                        />
                      </LazyLoad>
                    );
                  }

                  return (
                    <LazyLoad height={110} key={match.matchId}>
                      <MatchCard
                        match={match}
                        teamAFavorite={this.isFavoriteTeam(match.teamA.teamId)}
                        teamBFavorite={this.isFavoriteTeam(match.teamB.teamId)}
                        tournamentFavorite={this.isFavoriteTournament(
                          match.tournament.tournamentId
                        )}
                      />
                    </LazyLoad>
                  );
                })}
              </Fragment>
            ))}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

MatchList.contextType = UserContext;

const MatchListDate = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.GRAY};
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

export default MatchList;
